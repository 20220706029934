<template>
  <b-card no-body :header-bg-variant="group.is_sub?'transparent':'light'" :key="'card-group-' + group.name"
          :class="group.is_sub?'mb-4' : 'mb-4 shadow'" v-if="bcds2grp != null">
    <template v-slot:header>
      <b-row>
        <b-col lg="3">
          <h4>
            {{ group.name }}
          </h4>
          <b-badge v-if="group.do_idm_sync" variant="primary">{{ $t('components.group_card.idm-sync') }}</b-badge>
          <b-badge v-if="group.is_admin" variant="danger"
                   :title="$t('components.group_card.OU_administration_assignment')">{{ $t('system.admin') }}
          </b-badge>
          <b-badge v-if="group.is_own" variant="success" :title="$t('components.group_card.member_of_group')">
            {{ $t('system.member') }}
          </b-badge>
          <p class="text-muted">{{ $t('components.group_card.group_name') }}</p>
        </b-col>
        <b-col :lg="!group.is_sub?2:7">
          <p>
            {{ group.description }}
          </p>
          <p class="text-muted">{{ $t('system.description') }}</p>
        </b-col>
        <b-col v-if="!group.is_sub">
          <p>
            <b-link :to="'/org/ou/'+group.ou_short_name">{{ group.ou_short_name }}</b-link>
          </p>
          <p class="text-muted">{{ $tc('system.organizational_unit', 1) }}</p>
        </b-col>
        <b-col lg="2">
          <b-button-group class="d-flex">
            <b-button variant="outline-secondary" :id="'show-evlog-group-' + group.name + _uid"
                      @click="showEVLog(group)">
              <netvs-icon icon="evlog"></netvs-icon>
            </b-button>
            <b-tooltip :target="'show-evlog-group-' +  group.name + _uid" triggers="hover"
                       variant="secondary" placement="bottom">
              {{ $t('system.event_log') }}
            </b-tooltip>
            <b-button @click="editGroup(group)" variant="outline-primary"
                      :id="'button-edit-group-' +  group.name+ _uid">
              <netvs-icon icon="edit"></netvs-icon>
            </b-button>
            <b-tooltip :target="'button-edit-group-' +  group.name+ _uid" triggers="hover"
                       variant="primary" placement="bottom">
              {{ $t('components.group_card.edit_group') }}
            </b-tooltip>
            <b-button @click="deleteGroup(group)" variant="outline-danger"
                      :id="'button-delete-group-' +  group.name+ _uid">
              <netvs-icon icon="delete"></netvs-icon>
            </b-button>
            <b-tooltip :target="'button-delete-group-' + group.name + _uid" triggers="hover"
                       variant="danger" placement="bottom">
              {{ $t('components.group_card.delete_group') }}
            </b-tooltip>
          </b-button-group>
        </b-col>
      </b-row>
    </template>
    <b-button block squared variant="outline-secondary"
              v-b-toggle="group.name.replace(' ', '_') + '-collapse-bcds' +_uid">
      <netvs-icon icon="bcd"></netvs-icon>
      {{ $tc('system.bcd', 2) }} ({{ $t('components.group_card.address_space') }})
      <b-badge pill v-if="bcds2grp[group.name]">{{ bcds2grp[group.name].length }}</b-badge>
      <b-badge pill v-else variant="warning">0</b-badge>
      <br/>
      <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
    </b-button>
    <b-collapse :id="group.name.replace(' ', '_') + '-collapse-bcds' + _uid">
      <b-table :items="bcds2grp[group.name]" :fields="bcd_list_fields">
        <template v-slot:cell(bcd_name)="data">
          <b-link :to="'/dnsvs/bcds/'+data.item.bcd_name">{{ data.item.bcd_name }}</b-link>
        </template>
        <template v-slot:cell(bcd_description)="data">
          {{ bcdsByName[data.item.bcd_name] ? bcdsByName[data.item.bcd_name].description : '' }}
        </template>
        <template v-slot:head(actions)>
          <b-button block variant="outline-success"
                    :id="'button-assign-bcd-' +  group.name.replace(' ', '_') + _uid"
                    @click="createBcd2Group(group.name)">
            <netvs-icon icon="create"></netvs-icon>
          </b-button>
          <b-tooltip :target="'button-assign-bcd-' +  group.name.replace(' ', '_') + _uid" triggers="hover"
                     variant="success" placement="left">
            {{ $t('components.group_card.assign_bcd') }}
          </b-tooltip>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group class="d-flex">
            <b-button @click="deleteBcdItem(data.item)" variant="outline-danger"
                      :id="'button-unassign-bcd-' +  data.item.bcd_name + _uid">
              <netvs-icon icon="unlink"></netvs-icon>
            </b-button>
            <b-tooltip :target="'button-unassign-bcd-' +  data.item.bcd_name + _uid" triggers="hover"
                       variant="danger" placement="left">
              {{ $t('components.group_card.remove_assignment') }}
            </b-tooltip>
          </b-button-group>
        </template>
      </b-table>
    </b-collapse>
    <b-button block squared variant="outline-secondary"
              v-b-toggle="group.name.replace(' ', '_') + '-collapse-domains' + _uid">
      <netvs-icon icon="fqdn"></netvs-icon>
      {{ $tc('system.domain', 2) }} ({{ $t('components.group_card.name_space') }})
      <b-badge pill v-if="fqdn2grp[group.name]">{{ fqdn2grp[group.name].length }}</b-badge>
      <b-badge pill v-else variant="warning">0</b-badge>
      <br/>
      <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
    </b-button>
    <b-collapse :id="group.name.replace(' ', '_') + '-collapse-domains' + _uid">
      <b-table :items="fqdn2grp[group.name]" :fields="fqdn_list_fields">
        <template v-slot:head(actions)>
          <b-button block variant="outline-success"
                    :id="'button-assign-fqdn-' +  group.name.replace(' ', '_') + _uid"
                    @click="createFqdn2Group(group.name)">
            <netvs-icon icon="create"></netvs-icon>
          </b-button>
          <b-tooltip :target="'button-assign-fqdn-' +  group.name.replace(' ', '_') + _uid" triggers="hover"
                     variant="success" placement="left">
            {{ $t('components.group_card.assign_domain') }}
          </b-tooltip>
        </template>
        <template v-slot:cell(fqdn_value)="data">
          <b-link :to="'/dnsvs/fqdns/'+data.item.fqdn_value">{{ data.item.fqdn_value }}</b-link>
          <b-badge v-if="data.item.is_dhcp_domain">{{ $t('components.group_card.dhcp_domain') }}</b-badge>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group class="d-flex">
            <b-button @click="deleteFqdnItem(data.item)" variant="outline-danger"
                      :id="'button-unassign-fqdn-' +  data.item.fqdn_value + _uid">
              <netvs-icon icon="unlink"></netvs-icon>
            </b-button>
            <b-tooltip :target="'button-unassign-fqdn-' +  data.item.fqdn_value + _uid" triggers="hover"
                       variant="danger" placement="bottom">
              {{ $t('components.group_card.remove_assignment') }}
            </b-tooltip>
          </b-button-group>
        </template>
      </b-table>
    </b-collapse>
    <b-button block squared variant="outline-secondary"
              v-b-toggle="group.name.replace(' ', '_') + '-collapse-members' +_uid">
      <netvs-icon icon="group_members"></netvs-icon>
      {{ $tc('system.group_member', 2) }}
      <b-badge pill v-if="mgrs2grp && group.name in mgrs2grp">{{ mgrs2grp[group.name].length }}</b-badge>
      <b-badge pill v-else variant="warning">0</b-badge>
      <br/>
      <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
    </b-button>
    <b-collapse :id="group.name.replace(' ', '_') + '-collapse-members' + _uid">
      <b-table :items="getMgr2grp(group)" :fields="mgr_list_fields">
        <template v-slot:cell(email)="data">
          <b-link :href="`mailto:${mgrs_mail(data.item.login_name)}`">
            {{ mgrs[data.item.login_name].email }}
          </b-link>
        </template>
        <template v-slot:head(email)>
          {{ $t('system.email') }}
          <b-link class="ml-1" v-if="(group_email_cache[group.name] || []).length > 0"
                  :href="`mailto:${group_email_cache[group.name].join(';')}?subject=Betreuer der Gruppe '${group.name}'`">
            <netvs-icon icon="mail"></netvs-icon>
          </b-link>
        </template>
        <template v-slot:cell(description)="data">
          {{ mgrs[data.item.mgr_login_name]?.description || '' }}
        </template>
        <template v-slot:cell(name)="data">
          <MgrLine :mgr="data.item"></MgrLine>
        </template>
        <template v-slot:head(actions)>
          <b-button block variant="outline-success"
                    :id="'button-assign-mgr-' +  group.name + _uid" @click="createMgr2Group(group.name)">
            <netvs-icon icon="create"></netvs-icon>
          </b-button>
          <b-tooltip :target="'button-assign-mgr-' +  group.name  + _uid" triggers="hover"
                     variant="success" placement="left">
            {{ $t('components.group_card.assign_account') }}
          </b-tooltip>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group class="d-flex">
            <b-button @click="deleteMgrItem({mgr_login_name: data.item.login_name, group_name: group.name})" variant="outline-danger"
                      :id="'button-unassign-mgr-' +  data.item.login_name + _uid">
              <netvs-icon icon="unlink"></netvs-icon>
            </b-button>
            <b-tooltip :target="'button-unassign-mgr-' +  data.item.login_name  + _uid" triggers="hover"
                       variant="danger" placement="left">
              {{ $t('components.group_card.remove_assignment') }}
            </b-tooltip>
          </b-button-group>
        </template>
      </b-table>
    </b-collapse>
    <template v-if="!group.is_sub">
      <b-button block squared variant="outline-secondary"
                v-b-toggle="group.name.replace(' ', '_') + '-collapse-subgroups' +_uid">
        <netvs-icon icon="subgroups"></netvs-icon>
        {{ $tc('system.subgroup', 2) }}
        <b-badge pill v-if="subgroups && subgroups.length > 0">{{ subgroups.length }}</b-badge>
        <b-badge pill v-else>0</b-badge>
        <br/>
        <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
      </b-button>
      <b-collapse :id="group.name.replace(' ', '_') + '-collapse-subgroups' + _uid">
        <b-container fluid style="margin-top: 20px">
          <b-button style="margin-bottom: 20px" block variant="outline-success"
                    :id="'button-create-subgroup-' +  group.name.replace(' ', '_') + _uid"
                    @click="createSubgroup(group.name)"
                    v-if="group.is_own">
            <netvs-icon icon="create"></netvs-icon>
            {{ $t('components.group_card.create_subgroup') }}
          </b-button>
          <b-alert v-else variant="info" class="text-center" show>
            {{ $t('components.group_card.subgroups_members_only') }}
          </b-alert>
          <div v-if="!subgroups || subgroups.length === 0"
               class="font-italic text-center mb-3">
            {{ $t('components.group_card.no_subgroups') }}
          </div>
          <GroupCard v-for="sub in subgroups" v-bind:key="sub.name"
                     :group="sub"
                     :groups="groups"
                     :edit-fqdn2-group="editFqdn2Group"
                     :create-mgr2-group="createMgr2Group"
                     :mgrs2grp="mgrs2grp"
                     :fqdn2grp="fqdn2grp"
                     :bcds2grp="bcds2grp"
                     :create-fqdn2-group="createFqdn2Group"
                     :mgrs="mgrs"
                     :delete-fqdn-item="deleteFqdnItem"
                     :delete-bcd-item="deleteBcdItem"
                     :delete-mgr-item="deleteMgrItem"
                     :create-bcd2-group="createBcd2Group"
                     :delete-group="deleteGroup"
                     :edit-group="editGroup"
                     :show-e-v-log="showEVLog"
                     :bcds-by-name="bcdsByName"
          ></GroupCard>
        </b-container>
      </b-collapse>
    </template>
  </b-card>
</template>

<script>

import MgrLine from '@/components/MgrLine.vue'

export default {
  name: 'GroupCard',
  components: {
    MgrLine,
    GroupCard: () => import('./GroupCard.vue'),
  },
  computed: {
    group_email_cache() {
      const result = {}
      for (const group_name in this.mgrs2grp) {
        result[group_name] = this.group2mgr_emails(group_name)
      }
      return result
    },
    bcd_list_fields() {
      return [
        {
          key: 'bcd_name',
          label: this.$tc('system.bcd', 1),
          sortable: true
        },
        {
          key: 'bcd_description',
          label: this.$t('system.description'),
          sortable: false
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    },
    fqdn_list_fields() {
      return [
        {
          key: 'fqdn_value',
          label: this.$t('system.fqdn'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    },
    subgroup_list_fields() {
      return [
        {
          key: 'name',
          label: this.$t('system.name'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    },
    mgr_list_fields() {
      return [
        {
          key: 'name',
          label: this.$t('components.group_card.name'),
          sortable: false
        },
        {
          key: 'description',
          label: this.$t('system.description'),
          sortable: false
        },
        {
          key: 'email',
          label: this.$t('system.email'),
          sortable: false
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    }
  },
  props: {
    group: {
      type: Object,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    mgrs: {
      type: Object,
      required: true
    },
    bcds2grp: {
      type: Object,
      required: true
    },
    mgrs2grp: {
      type: Object,
      required: true
    },
    bcdsByName: {
      type: Object,
      required: true
    },
    fqdn2grp: {
      type: Object,
      required: true
    },
    subgroups: {
      type: Array,
      required: false
    },
    deleteMgrItem: {
      type: Function,
      required: true
    },
    deleteFqdnItem: {
      type: Function,
      required: true
    },
    deleteBcdItem: {
      type: Function,
      required: true
    },
    deleteGroup: {
      type: Function,
      required: true
    },
    editGroup: {
      type: Function,
      required: true
    },
    createSubgroup: {
      type: Function,
      required: false
    },
    createMgr2Group: {
      type: Function,
      required: true
    },
    createBcd2Group: {
      type: Function,
      required: true
    },
    createFqdn2Group: {
      type: Function,
      required: true
    },
    showEVLog: {
      type: Function,
      required: true
    }
  },
  methods: {
    getMgr2grp(group) {
      const group_mgrs = (this.mgrs2grp[group.name] || []).map(mgr => mgr.mgr_login_name)
      return group.name in this.mgrs2grp ? Object.entries(this.mgrs).filter(([member_name, _]) => group_mgrs.includes(member_name)).map(([_, mgr]) => mgr) : []
    },
    group2mgr_emails(group_name) {
      const real_mgrs_in_grp = (this.mgrs2grp[group_name] || [])
        .map(m2g => this.mgrs[m2g.mgr_login_name])
        .filter(mgr => mgr.email)
        .map(mgr => this.mgrs_mail(mgr.login_name))
      return [...new Set(real_mgrs_in_grp)]
    },
    mgrs_mail(user_name) {
      if (this.mgrs[user_name]) {
        return this.mgrs[user_name].first_name + ' ' + this.mgrs[user_name].last_name +
          '<' + this.mgrs[user_name].email + '>'
      }
      return ''
    },
    is_svc(user_name) {
      if (user_name in this.mgrs || {}) {
        return this.mgrs[user_name].is_svc
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
