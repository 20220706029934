// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'name', 'sub_id', 'description', 'do_idm_sync', 'parent_name', 'ou_short_name', 'do_copy_assignments', 'do_refresh_idm_sync', 'idm_sync_max_mgr_count']}
  },
  create (config, {name_new, sub_id_new, description_new, do_idm_sync_new = false, parent_name_new, ou_short_name_new, do_copy_assignments_new = true, do_refresh_idm_sync_new = false, idm_sync_max_mgr_count_new = 20}) {
    const params = { 'new': { 'name': name_new, 'sub_id': sub_id_new, 'description': description_new, 'do_idm_sync': do_idm_sync_new, 'parent_name': parent_name_new, 'ou_short_name': ou_short_name_new, 'do_copy_assignments': do_copy_assignments_new, 'do_refresh_idm_sync': do_refresh_idm_sync_new, 'idm_sync_max_mgr_count': idm_sync_max_mgr_count_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/group/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'name'], 'new': [ ]}
  },
  delete (config, {name_old}) {
    const params = { 'new': { }, 'old': { 'name': name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/group/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, name, is_own, is_sub, sub_id, gpk_list, is_admin, name_list, do_idm_sync, fetch_limit, name_regexp, sub_id_list, fetch_offset, ou_short_name, sub_id_regexp, parent_name_list, filter_params_dict, idm_sync_is_active, is_admin_or_is_own, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'name': name, 'is_own': is_own, 'is_sub': is_sub, 'sub_id': sub_id, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'is_admin': is_admin, 'name_list': (name_list === null) ? null : JSON.stringify(name_list), 'do_idm_sync': do_idm_sync, 'fetch_limit': fetch_limit, 'name_regexp': name_regexp, 'sub_id_list': (sub_id_list === null) ? null : JSON.stringify(sub_id_list), 'fetch_offset': fetch_offset, 'ou_short_name': ou_short_name, 'sub_id_regexp': sub_id_regexp, 'parent_name_list': (parent_name_list === null) ? null : JSON.stringify(parent_name_list), 'filter_params_dict': filter_params_dict, 'idm_sync_is_active': idm_sync_is_active, 'is_admin_or_is_own': is_admin_or_is_own, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/group/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'name'], 'new': [ 'name', 'sub_id', 'description', 'do_idm_sync', 'ou_short_name', 'do_refresh_idm_sync', 'idm_sync_max_mgr_count']}
  },
  update (config, {name_old, name_new, sub_id_new, description_new, do_idm_sync_new, ou_short_name_new, do_refresh_idm_sync_new, idm_sync_max_mgr_count_new}) {
    const params = { 'new': { 'name': name_new, 'sub_id': sub_id_new, 'description': description_new, 'do_idm_sync': do_idm_sync_new, 'ou_short_name': ou_short_name_new, 'do_refresh_idm_sync': do_refresh_idm_sync_new, 'idm_sync_max_mgr_count': idm_sync_max_mgr_count_new}, 'old': { 'name': name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/group/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
