<template>
  <div class="oe-overview">
    <template v-if="show_header">
      <h1 v-if="entry">{{ $t('views.cntl.groups.your_groups') }}</h1>
      <b-checkbox v-if="entry" :value="true" :unchecked-value="false" v-model="only_own" switch @change="refresh"
                  class="mb-3">{{ $t('views.cntl.groups.only_own_groups') }}
      </b-checkbox>
      <h1 v-else-if="groups">{{ main_groups[0].name }} <small class="text-muted">{{ $tc('system.group', 1) }}</small></h1>
      <p>
        {{ $t('views.cntl.groups.groups_description') }}
      </p>
      <i18n path="views.cntl.groups.subgroups_template" tag="p" for="views.cntl.groups">
        <template #account>
          <b-link to="/user/tokens">{{ $tc('system.subaccount', 2) }}</b-link>
        </template>
      </i18n>
      <hr/>
    </template>
    <Loading :data="groups">
      <b-button v-if="entry && show_add_group" style="margin-bottom: 20px" block variant="outline-success"
                id="button-create-group" @click="createSubgroup('')">
        <netvs-icon icon="create"/>
        {{ $t('views.cntl.groups.create_group') }}
      </b-button>
      <PaginatorList v-if="entry" :items="groups">
        <template v-slot:item="data">
          <GroupCard
            v-bind:key="data.item.name"
            :mgrs="mgrs"
            :group=data.item
            :groups="groups"
            :create-bcd2-group="createBCD2Group"
            :create-fqdn2-group="createFQDN2Group"
            :create-mgr2-group="createMgr2Group"
            :create-subgroup="createSubgroup"
            :delete-bcd-item="deleteBCDItem"
            :delete-mgr-item="deleteMgrItem"
            :delete-fqdn-item="deleteFQDNItem"
            :subgroups="groups_by_parent[data.item.name]"
            :fqdn2grp="fqdn2grp"
            :bcds2grp="bcds2grp"
            :mgrs2grp="mgrs2grp"
            :delete-group="deleteGroup"
            :edit-group="editGroup"
            :show-e-v-log="showEvLog"
            :bcds-by-name="bcds"
          >
          </GroupCard>
        </template>
      </PaginatorList>
      <GroupCard
        v-else-if="groups"
        :mgrs="mgrs"
        :group=main_groups[0]
        :groups="groups"
        :create-bcd2-group="createBCD2Group"
        :create-fqdn2-group="createFQDN2Group"
        :create-mgr2-group="createMgr2Group"
        :create-subgroup="createSubgroup"
        :delete-bcd-item="deleteBCDItem"
        :delete-mgr-item="deleteMgrItem"
        :delete-fqdn-item="deleteFQDNItem"
        :subgroups="groups_by_parent[groups[0].name]"
        :fqdn2grp="fqdn2grp"
        :bcds2grp="bcds2grp"
        :mgrs2grp="mgrs2grp"
        :delete-group="deleteGroup"
        :edit-group="editGroup"
        :show-e-v-log="showEvLog"
        :bcds-by-name="bcds"
      >
      </GroupCard>
      <div v-if="!groups || groups.length === 0"
           class="font-italic text-center mb-3">
        {{ $t('views.cntl.groups.no_groups') }}
      </div>
    </Loading>
    <DBEditor modal_id="assign_to_group" :object_function="db_editor_function"
              :object_fq_name="db_editor_object_fq_name"
              :presets="db_editor_presets"
              :non_optionals_order="db_editor_non_optionals_order"
              :object_title="db_editor_object_fq_name != null?db_editor_object_fq_name[db_editor_object_fq_name]:''"
              :old_data="db_editor_old_data">
    </DBEditor>
    <EVLogViewer v-if="evlogGroup" modal_id="groups-evlog"
                 :title=" $tc('system.group', 1) + ': ' + evlogGroup.name" ref_obj_fq="cntl.group"
                 refobj_id_field="name"
                 :refobj_id_value="evlogGroup.name"></EVLogViewer>
  </div>
</template>

<script>
import GroupService from '@/api-services/groups.service'
import Loading from '@/components/Loading'
import apiutil from '@/util/apiutil'
import transactionutil from '@/util/transactionutil'
import Mgr2GroupService from '@/api-services.gen/cntl.mgr2group'
import BCD2GroupService from '@/api-services.gen/nd.bcd2group'
import FQDN2GroupService from '@/api-services.gen/dns.fqdn2group'
import GenGroupService from '@/api-services.gen/cntl.group'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import GroupCard from '@/components/GroupCard'
import PaginatorList from '@/components/PaginatorList'
import EVLogViewer from '@/components/EVLogViewer'

export default {
  name: 'GroupOverview',
  components: { EVLogViewer, PaginatorList, GroupCard, DBEditor, Loading },
  props: {
    show_header: {
      type: Boolean,
      default: true
    },
    viewing_mgr: {
      type: String,
      default: null
    },
    show_add_group: {
      type: Boolean,
      default: true
    },
    show_sub_groups: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    db_editor_object_title() {
      return {
        'cntl.mgr2group': this.$t('views.cntl.groups.account_to_group'),
        'nd.bcd2group': this.$t('views.cntl.groups.bcd_to_group'),
        'dns.fqdn2group': this.$t('views.cntl.groups.domain_to_group')
      }
    },
    is_viewing_sub_user() {
      if (this.viewing_mgr) {
        return this.viewing_mgr.endsWith('[SUBM]')
      } else if (this.$store.state.impersonate_user) {
        return this.$store.state.impersonate_user.endsWith('[SUBM]')
      } else {
        return false
      }
    }
  },
  data() {
    return {
      mgrs: null,
      evlogGroup: null,
      db_editor_presets: {},
      db_editor_object_fq_name: null,
      db_editor_function: 'create',
      default_order: ['group_name', 'parent_name', 'description', 'do_copy_assignments', 'mgr_login_name', 'bcd_name', 'fqdn_value'],
      group_create_order: ['name', 'description', 'ou_short_name'],
      db_editor_non_optionals_order: ['group_name', 'parent_name', 'description', 'do_copy_assignments', 'mgr_login_name', 'bcd_name', 'fqdn_value'],
      db_editor_old_data: {},
      mgrs2grp: null,
      bcds2grp: null,
      fqdn2grp: null,
      groups: null,
      main_groups: null,
      ous: null,
      groups_by_parent: null,
      entry: true,
      bcds: null,
      only_own: true
    }
  },
  methods: {
    async refresh() {
      this.groups = this.mgrs2ou = this.ous = null
      this.entry = !('group' in this.$route.params)
      let res
      if (this.entry) {
        if (this.viewing_mgr) {
          res = await GroupService.list_own_for_sub_mgr(this.$store.state, this.viewing_mgr)
        } else if (this.$store.state.impersonate_user) {
          res = await GroupService.list_for_sub_mgr(this.$store.state, this.$store.state.impersonate_user) // own false -> null in api
        } else {
          res = await GroupService.list(this.$store.state, this.only_own ? true : null) // own false -> null in api
        }
      } else {
        res = await GroupService.get_by_name(this.$store.state, this.$route.params.group)
      }
      res = res.data

      if (this.entry && !this.show_sub_groups && !this.is_viewing_sub_user) {
        this.groups = res.group_list.filter(group => !group.is_sub)
      } else {
        this.groups = res.group_list
        this.main_groups = res.main_groups
      }
      if (!this.entry && this.groups.length > 0) {
        this.$store.commit('setNavigationRefreshHandle', { gpk: this.groups[0].gpk, objType: 'cntl.group' })
      }
      this.bcds = apiutil.dict_of_lists_by_value_of_array(res.bcd_list, 'name')
      this.bcds2grp = apiutil.dict_of_lists_by_value_of_array(res.bcd2group_list, 'group_name')
      this.mgrs2grp = apiutil.dict_of_lists_by_value_of_array(res.mgr2group_list, 'group_name')
      this.fqdn2grp = apiutil.dict_of_lists_by_value_of_array(res.fqdn2group_list, 'group_name')
      this.mgrs = Object.assign({}, apiutil.dict_by_value_of_array(res.mgr_list, 'login_name'), apiutil.dict_by_value_of_array(res.sub_mgr_list || [], 'login_name'))
      const subgroups = res.group_list.filter(group => group.is_sub)
      if (!this.entry) {
        this.groups_by_parent = {}
        this.groups_by_parent[this.groups[0].name] = res.subgroups
      } else {
        this.groups_by_parent = apiutil.dict_of_lists_by_value_of_array(subgroups, 'parent_name')
      }
    },
    merged_dict_of_lists_by_value_of_array(arrays, key) {
      const result = {}
      for (const a of arrays.filter(a => a)) {
        Object.assign(result, apiutil.dict_of_lists_by_value_of_array(a, key))
      }
      return result
    },
    deleteMgrItem(item) {
      const ta = transactionutil.generateDeleteElement('cntl.mgr2group', Mgr2GroupService.deleteParamsList(), item, item.mgr_login_name + ' ' + this.$t('views.cntl.groups.from_group') + ' ' + item.group_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    deleteBCDItem(item) {
      const ta = transactionutil.generateDeleteElement('nd.bcd2group', BCD2GroupService.deleteParamsList(), item, item.bcd_name + ' ' + this.$t('views.cntl.groups.from_group') + ' ' + item.group_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    deleteFQDNItem(item) {
      const ta = transactionutil.generateDeleteElement('dns.fqdn2group', FQDN2GroupService.deleteParamsList(), item, item.fqdn_value + ' ' + this.$t('views.cntl.groups.from_group') + ' ' + item.group_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    deleteGroup(item) {
      const ta = transactionutil.generateDeleteElement('cntl.group', GenGroupService.deleteParamsList(), item, this.$tc('system.group', 1) + ' ' + item.name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    createSubgroup(parent_name) {
      this.db_editor_function = 'create'
      this.db_editor_old_data = {}
      this.db_editor_presets = { parent_name: parent_name }
      this.db_editor_object_fq_name = 'cntl.group'
      this.db_editor_non_optionals_order = this.default_order
      if (parent_name === '') {
        this.db_editor_non_optionals_order = this.group_create_order
      }
      this.$root.$emit('bv::show::modal', 'assign_to_group')
    },
    showEvLog(group) {
      this.evlogGroup = group
      this.$root.$nextTick(() => {
        this.$root.$emit('bv::show::modal', 'groups-evlog')
      })
    },
    createMgr2Group(group_name) {
      this.db_editor_function = 'create'
      this.db_editor_old_data = {}
      this.db_editor_presets = { group_name: group_name }
      this.db_editor_object_fq_name = 'cntl.mgr2group'
      this.db_editor_non_optionals_order = this.default_order
      this.$root.$emit('bv::show::modal', 'assign_to_group')
    },
    createBCD2Group(group_name) {
      this.db_editor_function = 'create'
      this.db_editor_old_data = {}
      this.db_editor_presets = { group_name: group_name }
      this.db_editor_object_fq_name = 'nd.bcd2group'
      this.db_editor_non_optionals_order = this.default_order
      this.$root.$emit('bv::show::modal', 'assign_to_group')
    },
    createFQDN2Group(group_name) {
      this.db_editor_function = 'create'
      this.db_editor_old_data = {}
      this.db_editor_presets = { group_name: group_name }
      this.db_editor_object_fq_name = 'dns.fqdn2group'
      this.db_editor_non_optionals_order = this.default_order
      this.$root.$emit('bv::show::modal', 'assign_to_group')
    },
    editGroup(item) {
      window.console.debug(item)
      this.db_editor_function = 'update'
      this.db_editor_presets = item
      this.db_editor_old_data = item
      this.db_editor_object_fq_name = 'cntl.group'
      if (!item.is_sub) {
        this.db_editor_non_optionals_order = this.group_create_order
      } else {
        this.db_editor_non_optionals_order = this.default_order
      }
      this.$root.$emit('bv::show::modal', 'assign_to_group')
    }
  },
  async mounted() {
    await this.refresh()
  },
  watch: {
    $route() {
      this.refresh()
    }
  }
}
</script>

<style scoped>

</style>
